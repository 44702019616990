import axios from "axios";
import { findKey } from "lodash";
import { Address, AssetId, Chain } from "../../config/types";
import { Assets } from "../../config/assets";
import { ITimestampBlockMap } from "./blocks";

const getQuery = (fragments: string[]) => (
  /* GraphQL */ `
    query {
      ${fragments.join("\n")}
    }
  `
) 

const getBlockFragment = (token: string, block: number, timestamp: number) => (
  /* GraphQL */ `
    q_${token}_${timestamp}:token (
      id: "${token.toLowerCase()}"
      block: { number: ${block} }
    ) {
      derivedETH
    }  
  `
)

const getCurrentFragment = (token: string, assetId: string) => (
  /* GraphQL */ `
    q_${assetId}:token (
      id: "${token.toLowerCase()}"
    ) {
      derivedETH
    }  
  `
)

// 

const endpoints: any = {
  [Chain.SXN]: "https://graph.sx.technology/subgraphs/name/sharkswap/exchange",
  // [Chain.POLYGON]: "https://api.thegraph.com/subgraphs/name/sushiswap/matic-exchange"
}


export interface ITokenRatesHistorical {
  [token: string]: {
    [timestamp: number]: number;
  }
}

export interface ITokenRates {
  [token: string]: number;
}

export async function getTokenRatesByBlock(params: {
  chainId: number;
  queryTokens: Address[];
  timestampBlockMap: ITimestampBlockMap;
}) {
  const { chainId, queryTokens, timestampBlockMap } = params

  const endpoint = endpoints[chainId]
  if (!endpoint) {
    throw new Error(`Chain ID ${chainId} not supported`)
  }

  const sortedBlocks: number[] = Object.values(timestampBlockMap)
  sortedBlocks.sort((a, b) => b - a) 

  const baseToken = Assets.find(e => e.chainId === chainId && e.assetId === AssetId.USDC)?.address!
  const refToken = Assets.find(e => e.chainId === chainId && e.assetId === AssetId.WETH)?.address!
  let tokens = queryTokens.filter(e => e !== refToken) // .map(e => e.toLowerCase())
  if (!tokens.includes(baseToken)) {
    tokens.push(baseToken)
  }
  const fragments: string[] = []
  for (let token of tokens) {
    for (let block of sortedBlocks) {
      const timestamp = Number(findKey(timestampBlockMap, b => b === block))
      fragments.push(getBlockFragment(token, block, timestamp))
    }
  }
  
  const query = getQuery(fragments)

  const response = await axios.post(
    endpoint,
    JSON.stringify({
      query,
    }),
    { headers: { "Content-Type": "application/json" }}
  );
 
  // console.log("------- getTokenRatesByBlock", endpoint, query, response)

  const { data: { data }} = response

  const rates = Object.keys(data).reduce((acc: ITokenRatesHistorical, key: string) => {
    const [, address, timestampStr] = key.split("_")
    if (!acc[address]) {
      acc[address] = {}
    }
    const timestamp = Number(timestampStr)
    const baseRate = data[`q_${baseToken}_${timestamp}`].derivedETH
    const queryRate = data[key].derivedETH
    const rate = Number(queryRate) / Number(baseRate)
    acc[address][timestamp] = rate
    if (queryTokens.includes(refToken)) {
      if (!acc[refToken]) {
        acc[refToken] = {}
      }
      if (!acc[refToken][timestamp]) {
        acc[refToken][timestamp] = 1 / Number(baseRate)
      }
    }
    
    return acc
  }, {})

  return rates

}

export async function getTokenRates(params: {
  chainId: number;
  queryTokens: Address[];
}) {
  const { chainId, queryTokens } = params

  const endpoint = endpoints[chainId]
  if (!endpoint) {
    throw new Error(`Chain ID ${chainId} not supported`)
  }

  const baseToken = Assets.find(e => e.chainId === chainId && e.assetId === AssetId.USDC)!
  const refToken = Assets.find(e => e.chainId === chainId && e.assetId === AssetId.WETH)!

  let tokens = queryTokens.filter(e => e !== refToken.address) 
  if (!tokens.includes(baseToken.address!)) {
    tokens.push(baseToken.address!)
  }
  const fragments: string[] = []
  for (let token of tokens) {
    const assetId = Assets.find(e => e.chainId === chainId && e.address === token)!.assetId
    fragments.push(getCurrentFragment(token, assetId))
  }
  
  const query = getQuery(fragments)

  const response = await axios.post(
    endpoint,
    JSON.stringify({
      query,
    }),
    { headers: { "Content-Type": "application/json" }}
  );
 
  // console.log("------- getTokenRates", endpoint, query, response)

  const { data: { data }} = response

  const baseRate = data[`q_${baseToken.assetId}`].derivedETH
  let rates = Object.keys(data).reduce((acc: ITokenRates, key: string) => {
    const [, assetId] = key.split("_")
    const queryRate = data[key].derivedETH
    const rate = Number(queryRate) / Number(baseRate)
    acc[assetId] = rate
    
    return acc
  }, {})
  if (queryTokens.includes(refToken.address!)) {
    rates[refToken.assetId] = 1 / Number(baseRate)
  }

  return rates
}

