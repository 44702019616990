import { Call } from 'ethcall';
import { AssetId, Chain } from "../../config/types";
import { getMulticallProvider } from '../multicall';
import { getContractEthcall } from '../contracts';
import { Assets } from '../../config/assets';
import useSWR from 'swr';
import { fromWei } from '../../utils';
import { getTokenRates } from '../subgraphs/tokenRates';

export interface IPendingRewardsSummary {
  [account: string]: {
    [symbol: string]: [number, number];
  }
}

const CHAIN_ID = Chain.SXN
const REWARD_TOKENS = [AssetId.SX, AssetId.WETH, AssetId.USDC]

async function dataFetcher([accounts]: [accounts: string]) {
  const contractMeta = Assets.find(e => e.chainId === CHAIN_ID && e.assetId === AssetId.SX_STAKED_V2)!
  const tokens = REWARD_TOKENS.map(id => Assets.find(e => e.assetId === id && e.chainId === CHAIN_ID)!)

  const currentTokenRates = await getTokenRates({
    chainId: CHAIN_ID,
    queryTokens: tokens.map(e => e.address!),
  })

  let rewards: IPendingRewardsSummary = {};
  let calls: Call[] = [];

  const providerMulticall = await getMulticallProvider(CHAIN_ID)
  const contract = getContractEthcall(contractMeta);

  for (let account of accounts) {
    for (let token of tokens) {
      calls.push(contract.pendingRewards(token.address, account))
    }
  }
  const data = await providerMulticall.tryAll(calls)

  // console.log("------- useSxnStakingPendingRewards data", data)

  let i = 0
  for (let account of accounts) {
    for (let token of tokens) {
      if (!rewards[account]) {
        rewards[account] = {}
      }
      const amount = fromWei(data[i][0], token.decimals) // - fromWei(data[i][1], token.decimals)
      rewards[account][token.assetId] = [
        amount,
        amount * currentTokenRates[token.assetId]
      ] 
      i = i + 1
    }
  }
  // console.log("------- useSxnStakingPendingRewards rewards", rewards)

  return rewards 
}

export function useSxnStakingPendingRewards(
  accounts: string[],
) {
  const swrOptions = {
    revalidateOnFocus: false,
  };
  const fetchData = true;

  const { data, error } = useSWR(
    fetchData ? [accounts] : null, 
    dataFetcher,
    swrOptions
  );

  // console.log("-------- useSxnStakingPendingRewards", data, error)

  return { data, error };
}
