export type Address = string

export enum Chain {
  MAINNET = 1,
  POLYGON = 137,
  SXN = 416,
  SXR = 4162,
  ARBITRUM = 42161,
  BITCOIN = 99999,
  TORONTO = 647
}

export enum Preset {
  MCC = "mcc",
  PERSONAL = "personal",
  STORAGE = "storage",
  NEXT_GEN = "nextgen",
  SXN = "sxn",
  TEST = "test",
  TORONTO = "toronto",
  UNUSED = "unused",
  LOCKED = "locked"
  // TOURNAMENT = "tournament",
}

export enum Page {
  DASHBOARD = "dashboard",
  TRANSACTIONS = "transactions",
  FARMS = "farms",
  STAKING = "staking",
}

export enum AssetId {
  ETH = "ETH",
  WETH = "WETH",
  A_WETH = "A_WETH", // v3 borrow
  AM_WETH = "AM_WETH", // deposit
  MATIC = "MATIC",
  WMATIC = "WMATIC",
  VDM_MATIC = "VDM_MATIC",
  A_MATIC = "A_MATIC", // v3 borrow
  AM_MATIC = "AM_MATIC", // deposit
  DAI = "DAI",
  AM_DAI = "AM_DAI",
  USDC = "USDC",
  USDC_E = "USDC_E",
  AM_USDC = "AM_USDC",
  VDM_USDC = "VDM_USDC",
  SX = "SX",
  SX_DEPRECATED = "SX_DEPRECATED",
  SX_NATIVE = "SX_NATIVE",
  SX_STAKED = "SX_STAKED",
  SX_STAKED_V2 = "SX_STAKED_V2",
  SX_PENDING_V2 = "SX_PENDING_v2",
  SX_PENDING = "SX_PENDING",
  SHARK = "SHARK",
  SHARK_DEPRECATED = "SHARK_DEPRECATED",
  BTC = "BTC",
  WBTC = "WBTC",
  AM_WBTC = "AM_WBTC",
  DINO = "DINO",
  QUICK = "QUICK",
  CRYSTL = "CRYSTL",
  IDEX = "IDEX",
  SUSHI = "SUSHI",
  XSUSHI = "XSUSHI",
  OHM = "OHM",
  SOHM = "SOHM",
  DYDX = "DYDX",
  LFI = "LFI",
  ARB = "ARB",
  WETH_SX_SLP = "WETH_SX_SLP",
  WETH_SX_SLP_STAKED = "WETH_SX_SLP_STAKED",
  //WMATIC_SX_ALP = "WMATIC_SX_ALP",
  //SX_WMATIC_ALP_STAKED = "SX_WMATIC_ALP_STAKED",
  WETH_DAI_SLP = "WETH_DAI_SLP",
  WETH_DAI_SLP_STAKED = "WETH_DAI_SLP_STAKED",
  OHM_WETH_SLP = "OHM_WETH_SLP",
  DYDX_WETH_SLP = "DYDX_WETH_SLP",
  DYDX_WETH_SLP_STAKED = "DYDX_WETH_SLP_STAKED",
  USDC_DAI_SLP = "USDC_DAI_SLP",
  USDC_DAI_SLP_STAKED = "USDC_DAI_SLP_STAKED",
  //SX_USDC_ILP = "SX_USDC_ILP",
  //SX_USDC_ILP_STAKED = "SX_USDC_ILP_STAKED",
  SX_WETH_KLP = "SX_WETH_KLP",
  SX_WETH_KLP_STAKED = "SX_WETH_KLP_STAKED",
  SX_USDC_KLP = "SX_USDC_KLP",
  SX_USDC_KLP_STAKED = "SX_USDC_KLP_STAKED",
  SX_SHARK_KLP = "SX_SHARK_KLP",
  SX_SHARK_KLP_STAKED = "SX_SHARK_KLP_STAKED",
  WETH_USDC_KLP = "WETH_USDC_KLP",
  WETH_USDC_KLP_STAKED = "WETH_USDC_KLP_STAKED",
  SHARK_USDC_KLP = "SHARK_USDC_KLP",
  SHARK_USDC_KLP_STAKED = "SHARK_USDC_KLP_STAKED",
  SX_WMATIC_KLP = "SX_WMATIC_KLP",
  SX_WMATIC_KLP_STAKED = "SX_WMATIC_KLP_STAKED",
  USDC_WMATIC_KLP = "USDC_WMATIC_KLP",
  USDC_WMATIC_KLP_STAKED = "USDC_WMATIC_KLP_STAKED",
  WETH_WMATIC_KLP = "WETH_WMATIC_KLP",
  WETH_WMATIC_KLP_STAKED = "WETH_WMATIC_KLP_STAKED",
  LFI_SX_KLP = "LFI_SX_KLP",
  LFI_SX_KLP_STAKED = "LFI_SX_KLP_STAKED",

  USDC_SHARK_TLP = "USDC_SHARK_TLP",
  USDC_WETH_TLP = "USDC_WETH_TLP",
  WSX_WETH_TLP = "WSX_WETH_TLP",
  WSX_USDC_TLP = "WSX_USDC_TLP",
  WSX_SHARK_TLP = "WSX_SHARK_TLP",
  USDC_WMATIC_TLP = "USDC_WMATIC_TLP",
  WMATIC_WETH_TLP = "WMATIC_WETH_TLP",
  WSX_WMATIC_TLP = "WSX_WMATIC_TLP",
  SHARK_WETH_TLP = "SHARK_WETH_TLP",
  USDC_SHARK_TLP_STAKED = "USDC_SHARK_TLP_STAKED",
  USDC_WETH_TLP_STAKED = "USDC_WETH_TLP_STAKED",
  WSX_WETH_TLP_STAKED = "WSX_WETH_TLP_STAKED",
  WSX_USDC_TLP_STAKED = "WSX_USDC_TLP_STAKED",
  WSX_SHARK_TLP_STAKED = "WSX_SHARK_TLP_STAKED",
  USDC_WMATIC_TLP_STAKED = "USDC_WMATIC_TLP_STAKED",
  WMATIC_WETH_TLP_STAKED = "WMATIC_WETH_TLP_STAKED",
  WSX_WMATIC_TLP_STAKED = "WSX_WMATIC_TLP_STAKED",
  SHARK_WETH_TLP_STAKED = "SHARK_WETH_TLP_STAKED",

  WETH_SX_ULP = "WETH_SX_ULP",
}

export enum AssetCategory {
  STABLE_COIN = "STABLE_COIN",
  ALT_COIN = "ALT_COIN",
  PRIMARY_COIN = "PRIMARY_COIN",
  LP_TOKEN = "LP_TOKEN",
  DEPRECATED = "DEPRECATED",
  TEST = "TEST"
}

export enum Coin {
  BTC = "BTC",
  ETH = "ETH",
  SX = "SX",
  SHARK = "SHARK",
  MATIC = "MATIC",
  DAI = "DAI",
  USDC = "USDC",
  DINO = "DINO",
  QUICK = "QUICK",
  CRYSTL = "CRYSTL",
  IDEX = "IDEX",
  SUSHI = "SUSHI",
  XSUSHI = "XSUSHI",
  OHM = "OHM",
  DYDX = "DYDX",
  LFI = "LFI",
  ARB = "ARB",
  DEPRECATED = "DEPRECATED",
  WETH_SX_SLP = "WETH_SX_SLP",
  WMATIC_SX_ALP = "WMATIC_SX_ALP",
  SX_USDC_ILP = "SX_USDC_ILP",
  WETH_DAI_SLP = "WETH_DAI_SLP",
  OHM_WETH_SLP = "OHM_WETH_SLP",
  DYDX_WETH_SLP = "DYDX_WETH_SLP",
  USDC_DAI_SLP = "USDC_DAI_SLP",
  SX_WETH_KLP = "SX_WETH_KLP",
  SX_USDC_KLP = "SX_USDC_KLP",
  SX_SHARK_KLP = "SX_SHARK_KLP",
  WETH_USDC_KLP = "WETH_USDC_KLP",
  SHARK_USDC_KLP = "SHARK_USDC_KLP",
  SX_WMATIC_KLP = "SX_WMATIC_KLP",
  USDC_WMATIC_KLP = "USDC_WMATIC_KLP",
  WETH_WMATIC_KLP = "WETH_WMATIC_KLP",
  LFI_SX_KLP = "LFI_SX_KLP",

  USDC_SHARK_TLP = "USDC_SHARK_TLP",
  USDC_WETH_TLP = "USDC_WETH_TLP",
  WSX_WETH_TLP = "WSX_WETH_TLP",
  WSX_USDC_TLP = "WSX_USDC_TLP",
  WSX_SHARK_TLP = "WSX_SHARK_TLP",
  USDC_WMATIC_TLP = "USDC_WMATIC_TLP",
  WMATIC_WETH_TLP = "WMATIC_WETH_TLP",
  WSX_WMATIC_TLP = "WSX_WMATIC_TLP",
  SHARK_WETH_TLP = "SHARK_WETH_TLP",

  WETH_SX_ULP = "WETH_SX_ULP",
}

export enum AssetGroupId {
  ETH = "ETH",
  SX = "SX",
  SX_STAKED = "SX_STAKED",
  SHARK = "SHARK",
  DAI = "DAI",
  USDC = "USDC",
  BTC = "BTC",
  MATIC = "MATIC",
  DINO = "DINO",
  QUICK = "QUICK",
  CRYSTL = "CRYSTL",
  IDEX = "IDEX",
  SUSHI = "SUSHI",
  OHM = "OHM",
  DYDX = "DYDX",
  LFI = "LFI",
  ARB = "ARB",
  LP_TOKENS = "LP_TOKENS",
  STAKING_TOKENS = "STAKING_TOKENS"
}

export enum Exchange {
  UNISWAP = "UNISWAP",
  QUICKSWAP = "QUICKSWAP",
  PARASWAP = "PARASWAP",
  DINOSWAP = "DINOSWAP",
  SUSHISWAP = "SUSHISWAP",
  SHARKSWAP = "SHARKSWAP",
  APESWAP = "APESWAP",
  IDEX = "IDEX",
  CHAINLINK = "CHAINLINK",
  OTHER = "OTHER"
}

export enum Fiat {
  USD = "USD",
  CAD = "CAD",
  EUR = "EUR"
}

export enum AssetType {
  NATIVE = "NATIVE",
  TOKEN = "TOKEN",
  TOKEN_STAKED = "TOKEN_STAKED",
  TOKEN_PENDING = "TOKEN_PENDING",
  LP = "LP",
  LP_STAKED = "LP_STAKED",
  DEPOSIT = "DEPOSIT",
  BORROW = "BORROW",
  STAKING = "STAKING",
  DEPRECATED = "DEPRECATED",
  //TEST = "TEST",
}

export enum SubgraphType {
  MINICHEF = "minichef",
  EXCHANGE = "exchange",
  PARASWAP = "paraswap"
}

export interface IAsset {
  id: string;
  assetId: AssetId;
  coin: Coin;
  groupId: AssetGroupId;
  type: AssetType;
  category: AssetCategory;
  chainId: Chain;
  decimals: number;
  address?: Address;
  abi?: any;
  accounts?: Address[]; // to limit accounts queried
  inactive?: boolean;
  //relatedAssetIds?: AssetId[],
  //pid?: number; // for AssetType.LP_STAKED only
  //custom?: any; // non-standard queries
  //icons?: string[],
  //subgraphTypes?: SubgraphType[];
}


export interface IToken extends IAsset {
  type: 
      AssetType.TOKEN 
    | AssetType.NATIVE 
    | AssetType.TOKEN_PENDING 
    | AssetType.TOKEN_STAKED 
    | AssetType.STAKING 
    | AssetType.DEPOSIT 
    | AssetType.BORROW 
    | AssetType.DEPRECATED 
    //| AssetType.TEST
}

export interface ILiquidityPair extends IAsset {
  type: AssetType.LP;
  relatedAssetIds?: AssetId[],
  subgraphTypes?: SubgraphType[];
  custom?: any; // non-standard queries
}

export interface IFarm extends IAsset {
  type: AssetType.LP_STAKED;
  pid?: number; // for AssetType.LP_STAKED only
  subgraphTypes?: SubgraphType[];
  relatedAssetIds?: AssetId[],
}

export enum RateType {
  FIXED = "FIXED",
  DERIVED_V2 = "DERIVED_V2", // pulled from lp pair V2
  DERIVED_V3 = "DERIVED_V3", // pulled from uniswap pool V3
  INDIRECT = "INDIRECT", // computed based on two derived rates
  COMPLEX = "COMPLEX" // combined of two assets each with its own rate
}

export interface IRatePair {
  id: string;
  type: RateType;
  query?: Coin;
  base?: Coin | Fiat;
  presets?: Preset[],
  token1AsBase?: boolean;
  fixedRate?: number;
  path?: string[];
  //path0?: string[],
  //path1?: string[],
  chainId?: Chain;
  platformId?: Exchange;
  address?: string;
  abi?: any;
  token0?: AssetId;
  token1?: AssetId;
  fee?: number;
  liquidityPair?: string;
}

export enum TxType {
  LP_MINT = "lp_mint",
  LP_BURN = "lp_burn",
  LP_IN = "lp_in",
  LP_OUT = "lp_out",
  SWAP = "swap",
}

