/** @jsxImportSource @emotion/react */
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { STAKING_ACCOUNTS } from "../../services/staking"
import { useSxnStakingPendingRewards } from "../../services/staking/sxnStakingPendingRewards"
import { balanceFormatter, getAccountLabel } from "../../utils"
import { getTableStyles } from "../shared/styles"


function PendingSummary() {
  const { data } = useSxnStakingPendingRewards(STAKING_ACCOUNTS)

  let accounts: string[] = []
  let tokens: string[] = []
  let totalsByToken: { [token: string]: number; } = {}
  let valuesByAccount: { [account: string]: number; } = {}
  let totalValue = 0
  if (data) {
    for (let account of Object.keys(data)) {
      if (valuesByAccount[account] === undefined) {
        valuesByAccount[account] = 0
      }
      for (let token of Object.keys((data)[account])) {
        const [amount, value] = data[account][token]
        if (totalsByToken[token] === undefined) {
          totalsByToken[token] = 0
        }
        totalsByToken[token] += amount
        valuesByAccount[account] += value
        totalValue += value
        if (!accounts.includes(account) && amount) {
          accounts.push(account)
        }
        if (!tokens.includes(token) && amount) {
          tokens.push(token)
        }
      }
    }
  }

  // console.log("------- PendingSummary", data)

  const styles = getTableStyles()

  return (data ?
    <Box>
      <TableContainer css={styles.container} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">Pending</Typography>
              </TableCell>
              {tokens.map(token => (
                <TableCell align="right" key={`col-${token}`}>
                  {token}
                </TableCell>
              ))}
              <TableCell align="right">USD</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {accounts.map(account => (
              <TableRow key={`row-${account}`}>
                <TableCell>
                  {getAccountLabel(account)}
                </TableCell>
                {tokens.map((token, i) => (
                  <TableCell align="right" key={`cell-${account}-${i}`}>
                    {balanceFormatter(data[account][token][0], { coin: token })}
                  </TableCell>
                ))}
                <TableCell align="right">
                  {balanceFormatter(valuesByAccount[account])}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">Total</Typography>
              </TableCell>
              {tokens.map(token => (
                <TableCell align="right" key={`footer-${token}`}>
                  <strong>{balanceFormatter(totalsByToken[token], { coin: token })}</strong>
                </TableCell>
              ))}
              <TableCell align="right">
                <strong>{balanceFormatter(totalValue)}</strong>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box> : null
  )
}

export default PendingSummary
