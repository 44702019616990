import axios from "axios";
import { Chain } from "../../config/types";

const getQuery = (fragments: string[]) => (
  /* GraphQL */ `
    query {
      ${fragments.join("\n")}
    }
  `
) 

const getBlockFragment = (timestamp: number) => (
  /* GraphQL */ `
    q${timestamp}:blocks (
      first: 1
      orderBy: timestamp
      orderDirection: desc
      where: {
        timestamp_lte: ${timestamp}
      }
    ) {
      id
      number
      timestamp   
    }  
  `
)

const endpoints: any = {
  [Chain.SXN]: "https://graph.sx.technology/subgraphs/name/sharkswap/blocks",
  [Chain.POLYGON]: "https://api.thegraph.com/subgraphs/name/matthewlilley/polygon-blocks"
}

export interface ITimestampBlockMap {
  [timestamp: number]: number;
}

export async function getBlocksByTimestamps(timestamps: number[], chainId: number):Promise<ITimestampBlockMap> {
  const endpoint = endpoints[chainId]
  if (chainId === Chain.POLYGON) {
    return {}
  }
  if (!endpoint) {
    throw new Error(`Chain ID ${chainId} not supported`)
  }

  const blockFragments = timestamps.map(timestamp => getBlockFragment(timestamp))
  const query = getQuery(blockFragments)

  const response = await axios.post(
    endpoint,
    JSON.stringify({
      query,
    }),
    { headers: { "Content-Type": "application/json" }}
  );
  
  // console.log("------ getBlocksByTimestamps", query, response)

  const { data: { data }} = response

  console.log("------ getBlocksByTimestamps data", data, Object.values(data))

  const timestampBlockMap = Object.keys(data).reduce((acc: ITimestampBlockMap, key: string) => {
    if (data[key] && data[key].length > 0) {
      acc[Number(key.substring(1))] = Number(data[key][0].number)
      // acc[e[0].timestamp] = Number(e[0].number)
    }
    return acc
  }, {})

  return timestampBlockMap
}

/*

query($timestamp: Int) {
  blocks (
    first: 1
    orderBy: timestamp
    orderDirection: desc
    where: {
      timestamp_lte: $timestamp
    }
  ) {
    id
    number
    timestamp   
  }
}


*/
