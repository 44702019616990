import { Address, Chain } from "./types";

export const ETHERSCAN_API_KEY = "GNN3782YMFSDPK72K469JV226WWCCF2N91";
export const POLYGONSCAN_API_KEY = "437Z3EBGY1PG7Z4FIHMRIDJQ5DEHAN37Q3";
export const ALCHEMY_API_KEY = "frqA9uDAjaJFRLtM9DdvcLJ2P4dHFSCF";

export const WS_URLS: {
  [chainId: number]: string;
} = {
  [Chain.POLYGON]: "wss://polygon-mainnet.g.alchemy.com/v2/U7e88z2kwBzR9gp4g6WqgcGhVS2mtfdM",
  [Chain.MAINNET]: "wss://eth-mainnet.alchemyapi.io/v2/frqA9uDAjaJFRLtM9DdvcLJ2P4dHFSCF",
  [Chain.ARBITRUM]: "wss://arb1.arbitrum.io/rpc",
  [Chain.SXN]: "wss://rpc.sx.technology/ws",
  [Chain.SXR]: "wss://ws.sx-rollup.gelato.digital",
}

export const RPC_URLS: {
  [chainId: number]: string[];
} = {
  [Chain.MAINNET]: [
    "https://eth-mainnet.alchemyapi.io/v2/frqA9uDAjaJFRLtM9DdvcLJ2P4dHFSCF",
    "https://mainnet.infura.io/v3/a0564f10f2364bb49ae9b335437052ef",
    "https://eth-mainnet.gateway.pokt.network/v1/lb/60e517ea76cfec00352bcdab"
  ],
  [Chain.POLYGON]: [
    "https://polygon-mainnet.g.alchemy.com/v2/U7e88z2kwBzR9gp4g6WqgcGhVS2mtfdM",
    "https://polygon-mainnet.infura.io/v3/a0564f10f2364bb49ae9b335437052ef",
  ],
  [Chain.SXN]: [
    "https://rpc.sx.technology/" //"http://validator-4.sx.technology:10002" //"http://3.98.208.248:10002"//
  ],
  [Chain.TORONTO]: [
    "https://rpc.toronto.sx.technology/" //"http://validator-4.sx.technology:10002" //"http://3.98.208.248:10002"//
  ],
  [Chain.ARBITRUM]: [
    "https://arb1.arbitrum.io/rpc",
  ],
  [Chain.SXR]: [
    "https://rpc.sx-rollup.gelato.digital"
  ],
}

export const STATIC_RPC_URLS: {
  [chainId: number]: string;
} = {
  [Chain.MAINNET]: RPC_URLS[Chain.MAINNET][0],
  [Chain.ARBITRUM]: RPC_URLS[Chain.ARBITRUM][0],
  [Chain.POLYGON]: RPC_URLS[Chain.POLYGON][0],
  [Chain.SXN]: RPC_URLS[Chain.SXN][0],
  [Chain.SXR]: RPC_URLS[Chain.SXR][0],
  [Chain.TORONTO]: RPC_URLS[Chain.TORONTO][0],
}

export const MULTICALL_ADDRESSES: {
  [chainId: number]: {
    [key: string]: {
      address: Address,
      block: number;
    };
  }
} = {
  [Chain.SXN]: {
    multicall2: {
      address: "0x5e4730Eb8f992187607CE6bA524d1f599dC2b2fe",
      block: 2177255
    }
  },
  [Chain.SXR]: {
    multicall2: {
      address: "0x5e4730Eb8f992187607CE6bA524d1f599dC2b2fe",
      block: 16045
    }
  },
  [Chain.TORONTO]: {
    multicall2: {
      address: "0x49F46F5A3f83D2dddcb7c9170ee2e7D49Ce62F34",
      block: 5805435
    }
  }

}



/*
Polygon public RPCs:

https://rpc-mainnet.matic.network or
https://matic-mainnet.chainstacklabs.com or
https://rpc-mainnet.maticvigil.com or
https://rpc-mainnet.matic.quiknode.pro or
https://matic-mainnet-full-rpc.bwarelabs.com or
https://matic-mainnet-archive-rpc.bwarelabs.com



Get Ether Balance for a single Address
https://api.etherscan.io/api?module=account&action=balance&address=0xddbd2b932c763ba5b1b7ae3b362eac3e8d40121a&tag=latest&apikey=YourApiKeyToken

Get Ether Balance for multiple Addresses in a single call
https://api.etherscan.io/api?module=account&action=balancemulti&address=0xddbd2b932c763ba5b1b7ae3b362eac3e8d40121a,0x63a9975ba31b0b9626b34300f7f627147df1f526,0x198ef1ec325a96cc354c7266a038be8b5c558f67&tag=latest&apikey=YourApiKeyToken
Separate addresses by comma, up to a maxium of 20 accounts in a single batch


https://eth-mainnet.alchemyapi.io/v2/frqA9uDAjaJFRLtM9DdvcLJ2P4dHFSCF

https://mainnet.infura.io/v3/a0564f10f2364bb49ae9b335437052ef

https://eth-mainnet.gateway.pokt.network/v1/lb/60e517ea76cfec00352bcdab



https://polygon-mainnet.infura.io/v3/a0564f10f2364bb49ae9b335437052ef

https://rpc-mainnet.maticvigil.com/v1/fa5e5e134d48e6264dd14d859b46b0ee1b69e9b0

https://rpc-mainnet.matic.quiknode.pro


*/
