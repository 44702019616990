import axios from "axios";
import useSWR from "swr";
import { toChecksumAddress } from "ethereum-checksum-address"
import { sxbetSubgraph } from "../../config";
import { tokens } from "../../config/assets/sxn";
import { fromWei } from "../../utils";
import { IStakingHarvestEvent, IStakingHarvestSummary, IStakingHarvestTx, STAKING_ACCOUNTS } from ".";
import { Chain } from "../../config/types";
import { ITimestampBlockMap, getBlocksByTimestamps } from "../subgraphs/blocks";
import { getTokenRates, getTokenRatesByBlock } from "../subgraphs/tokenRates";
import { IScope } from "../../state/app";
import { DEFAULT_END_DATE, DEFAULT_START_DATE } from "../subgraphs";

const query = /* GraphQL */`
  query($accounts: [String!]!, $startDate: Int, $endDate: Int) {
    harvestRewardsEvents(
      first: 1000
      orderBy: timestamp
      orderDirection: desc
      where: {      
        stakerAddress_in: $accounts
        timestamp_gte: $startDate
        timestamp_lte: $endDate
      }
    ) {
      id
      txHash
      timestamp
      amount
      tokenAddress
      stakerAddress
    }
  }
`;

const CHAIN_ID = Chain.SXN

async function dataFetcher([endpoint, query, variables] : [endpoint: string, query: string, variables: any]) {
  let data: any;
  const response = await axios.post(
    endpoint,
    JSON.stringify({
      query,
      variables,
    }),
    { headers: { "Content-Type": "application/json" }}
  );
  data = response.data;

  let events: IStakingHarvestEvent[] | undefined;
  let txs: IStakingHarvestTx[] | undefined;
  let summary: IStakingHarvestSummary | undefined;

  if (data) {
    const { data: { harvestRewardsEvents }} = data;

    events = harvestRewardsEvents.map((e: any) => {
      const tokenAddress = toChecksumAddress(e.tokenAddress);
      const token = tokens.find(t => t.address === tokenAddress);
  
      return ({
        chainId: Chain.SXN,
        txHash: e.txHash,
        accountAddress: toChecksumAddress(e.stakerAddress),
        tokenAddress,
        timestamp: Number(e.timestamp),
        asset: token?.assetId,
        amount: fromWei(e.amount, token?.decimals), 
      })
    });
  
    summary = {}; 
    for (const { asset, tokenAddress, amount } of (events || [])) {
      if (!summary[asset]) {
        summary[asset] = {
          tokenAddress,
          amount: 0,
          amountUSD: 0,
          currentValueUSD: 0,
        };
      }
      summary[asset].amount += amount;
      // summary[asset].amountUSD += amountUSD || 0;
    }
    console.log("------ summary", summary);

    txs = (events || []).reduce((acc, e) => {
      let tx = acc.find(t => t.txHash === e.txHash)
      if (!tx) {
        tx = {
          timestamp: e.timestamp,
          // block: 0,
          chainId: e.chainId,
          txHash: e.txHash,
          accountAddress: e.accountAddress,
          amounts: {},
          amountsUSD: {},
          currentValuesUSD: {},
        }
        acc.push(tx)
      }
      tx.amounts[e.asset] = e.amount
      tx.amountsUSD[e.asset] = 0
      return acc
    }, [] as IStakingHarvestTx[])

    const timestamps = txs.map(e => e.timestamp)
    const timestampBlockMap: ITimestampBlockMap = await getBlocksByTimestamps(timestamps, CHAIN_ID)

    // console.log("------ blockMap", blockMap)

    const queryTokens = Object.keys(summary).map(key => summary![key].tokenAddress)
    const tokenRates = await getTokenRatesByBlock({
      chainId: CHAIN_ID,
      queryTokens,
      timestampBlockMap
    })

    const currentTokenRates = await getTokenRates({
      chainId: CHAIN_ID,
      queryTokens,
    })

    // console.log("------ tokenRates", tokenRates, currentTokenRates)

    txs = txs.map(tx => {
      const block = timestampBlockMap[tx.timestamp]
      const amountsUSD = Object.keys(tx.amounts).reduce((acc: { [asset: string]: number }, symbol) => {
        const tokenAddress = summary![symbol].tokenAddress
        acc[symbol] = tx.amounts[symbol] * tokenRates[tokenAddress][tx.timestamp]
        return acc
      }, {})
      return {
        ...tx, 
        block,
        amountsUSD,
      }
    })

    for (let tx of txs) {
      // const block = timestampBlockMap[tx.timestamp]
      //tx.block = block
      for (let symbol of Object.keys(tx.amounts)) {
        const tokenAddress = summary![symbol].tokenAddress
        const amountUSD = tx.amounts[symbol] * tokenRates[tokenAddress][tx.timestamp]
        const currentValueUSD = tx.amounts[symbol] * currentTokenRates[symbol]
        //tx.amountsUSD[symbol] = amountUSD
        summary[symbol].amountUSD += amountUSD
        summary[symbol].currentValueUSD += currentValueUSD
      }
    }
  }

  // console.log("------ useSxnStaking sxn", txs)

  return events && txs && summary ? { txs, summary } : {};
}

export function useSxnStaking(shouldFetch: boolean, scope: IScope) {
  const swrOptions = {
    revalidateOnFocus: false,
  };

  const endpoint = sxbetSubgraph;
  const variables = {
    accounts: STAKING_ACCOUNTS,
    startDate: scope.startDate || DEFAULT_START_DATE,
    endDate: scope.endDate || DEFAULT_END_DATE,
  }
  
  const fetchData = shouldFetch;

  const { data, error } = useSWR(
    fetchData ? [endpoint, query, variables] : null, 
    dataFetcher,
    swrOptions
  );

  if (error) {
    console.log("-------- fetching staking data error", error);
  }
  // console.log("--------- staking query", endpoint, query, variables)
  // console.log("--------- staking data", data);


  return { data, error };
}
