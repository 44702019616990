import { AssetCategory, AssetGroupId, AssetId, AssetType, Chain, Coin, IFarm, ILiquidityPair, IToken, SubgraphType } from "../types"
import MinTokenInfo from "../abi/MinTokenInfo.json";
import MinPairInfo from "../abi/MinPairInfo.json";
import MinFarmInfo from "../abi/MinFarmInfo.json";
import Staking from "../abi/Staking.json";
import StakingV2 from "../abi/StakingV2.json";

const tokens: IToken[] = [
  {
    id: `${AssetId.SX_NATIVE}:${Chain.SXN}`,
    assetId: AssetId.SX_NATIVE,
    coin: Coin.SX,
    groupId: AssetGroupId.SX,
    type: AssetType.NATIVE,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.SXN,
    decimals: 18,
  },
  {
    id: `${AssetId.SX}:${Chain.SXN}`,
    assetId: AssetId.SX,
    coin: Coin.SX,
    groupId: AssetGroupId.SX,
    type: AssetType.TOKEN,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.SXN,
    address: "0xaa99bE3356a11eE92c3f099BD7a038399633566f",
    decimals: 18,
    abi: MinTokenInfo
  },
  {
    id: `${AssetId.SHARK}:${Chain.SXN}`,
    assetId: AssetId.SHARK,
    coin: Coin.SHARK,
    groupId: AssetGroupId.SHARK,
    type: AssetType.TOKEN,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.SXN,
    address: "0x7Dc31a2FCBfbAd1ed4519111Fd33f78316BcBC81",
    decimals: 18,
    abi: MinTokenInfo
  },
  {
    id: `${AssetId.USDC}:${Chain.SXN}`,
    assetId: AssetId.USDC,
    coin: Coin.USDC,
    groupId: AssetGroupId.USDC,
    type: AssetType.TOKEN,
    category: AssetCategory.STABLE_COIN,
    chainId: Chain.SXN,
    address: "0xe2aa35C2039Bd0Ff196A6Ef99523CC0D3972ae3e",
    decimals: 6,
    abi: MinTokenInfo
  },
  {
    id: `${AssetId.WETH}:${Chain.SXN}`,
    assetId: AssetId.WETH,
    coin: Coin.ETH,
    groupId: AssetGroupId.ETH,
    type: AssetType.TOKEN,
    category: AssetCategory.PRIMARY_COIN,
    chainId: Chain.SXN,
    address: "0xA173954Cc4b1810C0dBdb007522ADbC182DaB380",
    decimals: 18,
    abi: MinTokenInfo
  },
  {
    id: `${AssetId.WMATIC}:${Chain.SXN}`,
    assetId: AssetId.WMATIC,
    coin: Coin.MATIC,
    groupId: AssetGroupId.MATIC,
    type: AssetType.TOKEN,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.SXN,
    address: "0xfa6F64DFbad14e6883321C2f756f5B22fF658f9C",
    decimals: 18,
    abi: MinTokenInfo
  },
  {
    id: `${AssetId.SX_STAKED}:${Chain.SXN}`,
    assetId: AssetId.SX_STAKED,
    coin: Coin.SX,
    groupId: AssetGroupId.SX_STAKED,
    type: AssetType.TOKEN_STAKED,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.SXN,
    address: "0x1d730f26293fc2F3495A8acB199b8726C24a6Cde",
    decimals: 18,
    abi: Staking,
  },
  {
    id: `${AssetId.SX_PENDING}:${Chain.SXN}`,
    assetId: AssetId.SX_PENDING,
    coin: Coin.SX,
    groupId: AssetGroupId.SX_STAKED,
    type: AssetType.TOKEN_PENDING,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.SXN,
    address: "0x1d730f26293fc2F3495A8acB199b8726C24a6Cde",
    decimals: 18,
    abi: Staking,
  },
  /*
  {
    id: `${AssetId.LFI}:${Chain.SXN}`,
    assetId: AssetId.LFI,
    coin: Coin.LFI,
    groupId: AssetGroupId.LFI,
    type: AssetType.TOKEN,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.SXN,
    address: "0x6fe538efe9b1de58bba3de506d363b9f4a055906", // 0x4C882ec256823eE773B25b414d36F92ef58a7c0C
    decimals: 18,
    abi: MinTokenInfo
  },
  */
  {
    id: `${AssetId.SX_STAKED_V2}:${Chain.SXN}`,
    assetId: AssetId.SX_STAKED_V2,
    coin: Coin.SX,
    groupId: AssetGroupId.SX_STAKED,
    type: AssetType.TOKEN_STAKED,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.SXN,
    address: "0x5A5f7752aa7261709d7932f10e4f78Ee23665D76",
    decimals: 18,
    abi: StakingV2,
  },
  {
    id: `${AssetId.SX_PENDING_V2}:${Chain.SXN}`,
    assetId: AssetId.SX_PENDING_V2,
    coin: Coin.SX,
    groupId: AssetGroupId.SX_STAKED,
    type: AssetType.TOKEN_PENDING,
    category: AssetCategory.ALT_COIN,
    chainId: Chain.SXN,
    address: "0x5A5f7752aa7261709d7932f10e4f78Ee23665D76",
    decimals: 18,
    abi: StakingV2,
  },

]

const lps: ILiquidityPair[] = [
  {
    id: `${AssetId.SX_USDC_KLP}:${Chain.SXN}`,
    assetId: AssetId.SX_USDC_KLP,
    coin: Coin.SX_USDC_KLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.SXN,
    address: "0x88123500716c483D2d0289caD564517F96743847",
    decimals: 18,
    abi: MinPairInfo,
    relatedAssetIds: [AssetId.SX, AssetId.USDC],
    subgraphTypes: [SubgraphType.EXCHANGE]
  },
  {
    id: `${AssetId.SX_WETH_KLP}:${Chain.SXN}`,
    assetId: AssetId.SX_WETH_KLP,
    coin: Coin.SX_WETH_KLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.SXN,
    address: "0x28d8f7E0F67c12cC49C128ec73C6265C7A36F84A",
    decimals: 18,
    abi: MinPairInfo,
    relatedAssetIds: [AssetId.WETH, AssetId.SX],
    subgraphTypes: [SubgraphType.EXCHANGE],
    custom: {
      deposits: [13.474597, 150_000]// [14 + 4.5, 124_845.986 + 37_099.244] 13.474597146891568454
    }
  },
  {
    id: `${AssetId.SX_WMATIC_KLP}:${Chain.SXN}`,
    assetId: AssetId.SX_WMATIC_KLP,
    coin: Coin.SX_WMATIC_KLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.SXN,
    address: "0x9e3a12f8d2ABc3e9c2814B59130bAFCBA876cEc7",
    decimals: 18,
    abi: MinPairInfo,
    relatedAssetIds: [AssetId.SX, AssetId.WMATIC],
    subgraphTypes: [SubgraphType.EXCHANGE]
  },  
  {
    id: `${AssetId.WETH_USDC_KLP}:${Chain.SXN}`,
    assetId: AssetId.WETH_USDC_KLP,
    coin: Coin.WETH_USDC_KLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.SXN,
    address: "0xeFdfed099D11B62A83e0015d766DC4e31bAEA06f",
    decimals: 18,
    abi: MinPairInfo,
    relatedAssetIds: [AssetId.WETH, AssetId.USDC],
    subgraphTypes: [SubgraphType.EXCHANGE]
  },
  {
    id: `${AssetId.SHARK_USDC_KLP}:${Chain.SXN}`,
    assetId: AssetId.SHARK_USDC_KLP,
    coin: Coin.SHARK_USDC_KLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.SXN,
    address: "0x581E260c033e8CbdeAfAd0C243dE4c0a5c32341b",
    decimals: 18,
    abi: MinPairInfo,
    relatedAssetIds: [AssetId.SHARK, AssetId.USDC],
    subgraphTypes: [SubgraphType.EXCHANGE]
  },  
  {
    id: `${AssetId.USDC_WMATIC_KLP}:${Chain.SXN}`,
    assetId: AssetId.USDC_WMATIC_KLP,
    coin: Coin.USDC_WMATIC_KLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.SXN,
    address: "0x1b3BcD599451601595238c61df59B85925887F5d",
    decimals: 18,
    abi: MinPairInfo,
    relatedAssetIds: [AssetId.USDC, AssetId.WMATIC],
    subgraphTypes: [SubgraphType.EXCHANGE]
  },  
  {
    id: `${AssetId.SX_SHARK_KLP}:${Chain.SXN}`,
    assetId: AssetId.SX_SHARK_KLP,
    coin: Coin.SX_SHARK_KLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.SXN,
    address: "0x70bDD69Fe7E7090642f1539991E77aBA6cCF3212",
    decimals: 18,
    abi: MinPairInfo,
    relatedAssetIds: [AssetId.SHARK, AssetId.SX],
    subgraphTypes: [SubgraphType.EXCHANGE]
  },

]

const farms: IFarm[] = [
  {
    id: `${AssetId.SX_USDC_KLP_STAKED}:${Chain.SXN}`,
    assetId: AssetId.SX_USDC_KLP_STAKED,
    coin: Coin.SX_USDC_KLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP_STAKED,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.SXN,
    address: "0xB376B1cCA2d3049480bFAca00af43263ea7bd128",
    decimals: 18,
    abi: MinFarmInfo,
    relatedAssetIds: [AssetId.SX_USDC_KLP],
    pid: 0,
    subgraphTypes: [SubgraphType.MINICHEF]
  },
  {
    id: `${AssetId.SX_WETH_KLP_STAKED}:${Chain.SXN}`,
    assetId: AssetId.SX_WETH_KLP_STAKED,
    coin: Coin.SX_WETH_KLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP_STAKED,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.SXN,
    address: "0xB376B1cCA2d3049480bFAca00af43263ea7bd128",
    decimals: 18,
    abi: MinFarmInfo,
    relatedAssetIds: [AssetId.SX_WETH_KLP],
    pid: 1,
    subgraphTypes: [SubgraphType.MINICHEF]
  },
  {
    id: `${AssetId.SX_WMATIC_KLP_STAKED}:${Chain.SXN}`,
    assetId: AssetId.SX_WMATIC_KLP_STAKED,
    coin: Coin.SX_WMATIC_KLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP_STAKED,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.SXN,
    address: "0xB376B1cCA2d3049480bFAca00af43263ea7bd128",
    decimals: 18,
    abi: MinFarmInfo,
    relatedAssetIds: [AssetId.SX_WMATIC_KLP],
    pid: 2,
    subgraphTypes: [SubgraphType.MINICHEF]
  },
  {
    id: `${AssetId.WETH_USDC_KLP_STAKED}:${Chain.SXN}`,
    assetId: AssetId.WETH_USDC_KLP_STAKED,
    coin: Coin.WETH_USDC_KLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP_STAKED,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.SXN,
    address: "0xB376B1cCA2d3049480bFAca00af43263ea7bd128",
    decimals: 18,
    abi: MinFarmInfo,
    relatedAssetIds: [AssetId.WETH_USDC_KLP],
    pid: 3,
    subgraphTypes: [SubgraphType.MINICHEF]
  },  {
    id: `${AssetId.SHARK_USDC_KLP_STAKED}:${Chain.SXN}`,
    assetId: AssetId.SHARK_USDC_KLP_STAKED,
    coin: Coin.SHARK_USDC_KLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP_STAKED,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.SXN,
    address: "0xB376B1cCA2d3049480bFAca00af43263ea7bd128",
    decimals: 18,
    abi: MinFarmInfo,
    relatedAssetIds: [AssetId.SHARK_USDC_KLP],
    pid: 4,
    subgraphTypes: [SubgraphType.MINICHEF]
  },


  /*
  {
    id: `${AssetId.SX_WETH_KLP_STAKED}:${Chain.SXN}`,
    assetId: AssetId.SX_WETH_KLP_STAKED,
    coin: Coin.SX_WETH_KLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP_STAKED,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.SXN,
    address: "0x7Cb0E2e944069D6d70A6f470154C4EEF3eB67530",
    decimals: 18,
    abi: MinFarmInfo,
    relatedAssetIds: [AssetId.SX_WETH_KLP],
    pid: 0,
    subgraphTypes: [SubgraphType.MINICHEF]
  },
  {
    id: `${AssetId.SX_SHARK_KLP_STAKED}:${Chain.SXN}`,
    assetId: AssetId.SX_SHARK_KLP_STAKED,
    coin: Coin.SX_SHARK_KLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP_STAKED,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.SXN,
    address: "0x7Cb0E2e944069D6d70A6f470154C4EEF3eB67530",
    decimals: 18,
    abi: MinFarmInfo,
    relatedAssetIds: [AssetId.SX_SHARK_KLP],
    pid: 1,
    subgraphTypes: [SubgraphType.MINICHEF]
  },
  {
    id: `${AssetId.WETH_USDC_KLP_STAKED}:${Chain.SXN}`,
    assetId: AssetId.WETH_USDC_KLP_STAKED,
    coin: Coin.WETH_USDC_KLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP_STAKED,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.SXN,
    address: "0x7Cb0E2e944069D6d70A6f470154C4EEF3eB67530",
    decimals: 18,
    abi: MinFarmInfo,
    relatedAssetIds: [AssetId.WETH_USDC_KLP],
    pid: 2,
    subgraphTypes: [SubgraphType.MINICHEF]
  },
  {
    id: `${AssetId.SHARK_USDC_KLP_STAKED}:${Chain.SXN}`,
    assetId: AssetId.SHARK_USDC_KLP_STAKED,
    coin: Coin.SHARK_USDC_KLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP_STAKED,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.SXN,
    address: "0x7Cb0E2e944069D6d70A6f470154C4EEF3eB67530",
    decimals: 18,
    abi: MinFarmInfo,
    relatedAssetIds: [AssetId.SHARK_USDC_KLP],
    pid: 3,
    subgraphTypes: [SubgraphType.MINICHEF]
  },
  {
    id: `${AssetId.SX_USDC_KLP_STAKED}:${Chain.SXN}`,
    assetId: AssetId.SX_USDC_KLP_STAKED,
    coin: Coin.SX_USDC_KLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP_STAKED,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.SXN,
    address: "0x7Cb0E2e944069D6d70A6f470154C4EEF3eB67530",
    decimals: 18,
    abi: MinFarmInfo,
    relatedAssetIds: [AssetId.SX_USDC_KLP],
    pid: 4,
    subgraphTypes: [SubgraphType.MINICHEF]
  },
  {
    id: `${AssetId.SX_WMATIC_KLP_STAKED}:${Chain.SXN}`,
    assetId: AssetId.SX_WMATIC_KLP_STAKED,
    coin: Coin.SX_WMATIC_KLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP_STAKED,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.SXN,
    address: "0x7Cb0E2e944069D6d70A6f470154C4EEF3eB67530",
    decimals: 18,
    abi: MinFarmInfo,
    relatedAssetIds: [AssetId.SX_WMATIC_KLP],
    pid: 7,
    subgraphTypes: [SubgraphType.MINICHEF]
  },
  {
    id: `${AssetId.USDC_WMATIC_KLP_STAKED}:${Chain.SXN}`,
    assetId: AssetId.USDC_WMATIC_KLP_STAKED,
    coin: Coin.USDC_WMATIC_KLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP_STAKED,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.SXN,
    address: "0x7Cb0E2e944069D6d70A6f470154C4EEF3eB67530",
    decimals: 18,
    abi: MinFarmInfo,
    relatedAssetIds: [AssetId.USDC_WMATIC_KLP],
    pid: 5,
    subgraphTypes: [SubgraphType.MINICHEF]
  },
  {
    id: `${AssetId.WETH_WMATIC_KLP_STAKED}:${Chain.SXN}`,
    assetId: AssetId.WETH_WMATIC_KLP_STAKED,
    coin: Coin.WETH_WMATIC_KLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP_STAKED,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.SXN,
    address: "0x7Cb0E2e944069D6d70A6f470154C4EEF3eB67530",
    decimals: 18,
    abi: MinFarmInfo,
    relatedAssetIds: [AssetId.WETH_WMATIC_KLP],
    pid: 6,
    subgraphTypes: [SubgraphType.MINICHEF]
  },
  {
    id: `${AssetId.LFI_SX_KLP_STAKED}:${Chain.SXN}`,
    assetId: AssetId.LFI_SX_KLP_STAKED,
    coin: Coin.LFI_SX_KLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP_STAKED,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.SXN,
    address: "0x7Cb0E2e944069D6d70A6f470154C4EEF3eB67530",
    decimals: 18,
    abi: MinFarmInfo,
    relatedAssetIds: [AssetId.LFI_SX_KLP],
    pid: 8,
    subgraphTypes: [SubgraphType.MINICHEF]
  },
  */
]

export { tokens, lps, farms }


  /*
  {
    id: `${AssetId.SX_WETH_KLP}:${Chain.SXN}`,
    assetId: AssetId.SX_WETH_KLP,
    coin: Coin.SX_WETH_KLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.SXN,
    address: "0xFBfCE65dFBa42ff80Ec26792905170F8f3a89303",
    decimals: 18,
    abi: MinPairInfo,
    relatedAssetIds: [AssetId.SX_DEPRECATED, AssetId.WETH],
    subgraphTypes: [SubgraphType.EXCHANGE]
  },
  {
    id: `${AssetId.SX_USDC_KLP}:${Chain.SXN}`,
    assetId: AssetId.SX_USDC_KLP,
    coin: Coin.SX_USDC_KLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.SXN,
    address: "0xbE1C99da6119D83ac42ED9c084c028729ad6B7d7",
    decimals: 18,
    abi: MinPairInfo,
    relatedAssetIds: [AssetId.SX_DEPRECATED, AssetId.USDC],
    subgraphTypes: [SubgraphType.EXCHANGE]
  },
  {
    id: `${AssetId.SX_SHARK_KLP}:${Chain.SXN}`,
    assetId: AssetId.SX_SHARK_KLP,
    coin: Coin.SX_SHARK_KLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.SXN,
    address: "0x3af0Ed081F4434A2dC76Abd14Ce6dAa5c000634e",
    decimals: 18,
    abi: MinPairInfo,
    relatedAssetIds: [AssetId.SX_DEPRECATED, AssetId.SHARK_DEPRECATED],
    subgraphTypes: [SubgraphType.EXCHANGE]
  },
  {
    id: `${AssetId.WETH_USDC_KLP}:${Chain.SXN}`,
    assetId: AssetId.WETH_USDC_KLP,
    coin: Coin.WETH_USDC_KLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.SXN,
    address: "0x2eB64684E240f5035E4401dC07d023B4deC39234",
    decimals: 18,
    abi: MinPairInfo,
    relatedAssetIds: [AssetId.WETH, AssetId.USDC],
    subgraphTypes: [SubgraphType.EXCHANGE]
  },
  {
    id: `${AssetId.SHARK_USDC_KLP}:${Chain.SXN}`,
    assetId: AssetId.SHARK_USDC_KLP,
    coin: Coin.SHARK_USDC_KLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.SXN,
    address: "0x0fD9b5AC5a61e5285730aDa2F5d1b733F88d3434",
    decimals: 18,
    abi: MinPairInfo,
    relatedAssetIds: [AssetId.SHARK_DEPRECATED, AssetId.USDC],
    subgraphTypes: [SubgraphType.EXCHANGE]
  },  
  {
    id: `${AssetId.USDC_WMATIC_KLP}:${Chain.SXN}`,
    assetId: AssetId.USDC_WMATIC_KLP,
    coin: Coin.USDC_WMATIC_KLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.SXN,
    address: "0x92927994C295AD12e3F879C6eF55095cd1753DA7",
    decimals: 18,
    abi: MinPairInfo,
    relatedAssetIds: [AssetId.USDC, AssetId.WMATIC],
    subgraphTypes: [SubgraphType.EXCHANGE]
  },  
  {
    id: `${AssetId.SX_WMATIC_KLP}:${Chain.SXN}`,
    assetId: AssetId.SX_WMATIC_KLP,
    coin: Coin.SX_WMATIC_KLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.SXN,
    address: "0xE871D9437a58c6E8A8FA01f24C4E87962aAEe6b7",
    decimals: 18,
    abi: MinPairInfo,
    relatedAssetIds: [AssetId.SX_DEPRECATED, AssetId.WMATIC],
    subgraphTypes: [SubgraphType.EXCHANGE]
  },  
  {
    id: `${AssetId.WETH_WMATIC_KLP}:${Chain.SXN}`,
    assetId: AssetId.WETH_WMATIC_KLP,
    coin: Coin.WETH_WMATIC_KLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.SXN,
    address: "0x364Ef16261efe2266a04AFCcD35a3A5F1116b14d",
    decimals: 18,
    abi: MinPairInfo,
    relatedAssetIds: [AssetId.WETH, AssetId.WMATIC],
    subgraphTypes: [SubgraphType.EXCHANGE]
  },  
  {
    id: `${AssetId.LFI_SX_KLP}:${Chain.SXN}`,
    assetId: AssetId.LFI_SX_KLP,
    coin: Coin.LFI_SX_KLP,
    groupId: AssetGroupId.LP_TOKENS,
    type: AssetType.LP,
    category: AssetCategory.LP_TOKEN,
    chainId: Chain.SXN,
    address: "0xb251C9B8712bd2253aEfc422fBD370b236f7AbDE",
    decimals: 18,
    abi: MinPairInfo,
    relatedAssetIds: [AssetId.LFI, AssetId.SX_DEPRECATED],
    subgraphTypes: [SubgraphType.EXCHANGE]
  },
  */



/*

  {
    id: `${AssetId.SX_DEPRECATED}:${Chain.SXN}`,
    assetId: AssetId.SX_DEPRECATED,
    coin: Coin.DEPRECATED,
    groupId: AssetGroupId.SX,
    type: AssetType.DEPRECATED,
    category: AssetCategory.DEPRECATED,
    chainId: Chain.SXN,
    address: "0x90d27E008d5Db7f0a3c90a15A8Dcc4Ca18cFc670",
    decimals: 18,
    abi: MinTokenInfo
  },
  {
    id: `${AssetId.SHARK_DEPRECATED}:${Chain.SXN}`,
    assetId: AssetId.SHARK_DEPRECATED,
    coin: Coin.DEPRECATED,
    groupId: AssetGroupId.SHARK,
    type: AssetType.DEPRECATED,
    category: AssetCategory.DEPRECATED,
    chainId: Chain.SXN,
    address: "0xa0cB58E7F783fce0F4042C790ea3045c48CD51e8",
    decimals: 18,
    abi: MinTokenInfo
  },



*/

